import axios from 'axios'
import { Link, navigate } from 'gatsby'
import React from 'react'
import { useState, useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import styled from 'styled-components'
import Seo from '../../components/Layout/Seo'
import Site from '../../components/Layout/Site'
import DefaultVector from '../../components/Shared/DefaultVector'
import { Subtitle, Title } from '../../components/Shared/SectionHeader.styles'
import Button from '../../components/UI/Button'
import { Card } from '../../components/UI/Card'
import Element from '../../components/UI/Element'
import { Field, FieldContainer, FieldGroup } from '../../components/UI/Forms'
import { Col, Container, Row } from '../../components/UI/Grid'
import ScaledImage from '../../components/UI/ScaledImage'
import { Section } from '../../components/UI/Section'
import { Heading } from '../../components/UI/Typography'

export const subCategory = category => {
  switch (category) {
    case 1:
      return 'Signal Conditioning / Control'
    case 2:
      return 'Time and Frequency'
    case 3:
      return 'Module Assembly Packaging'
    case 4:
      return 'Passive'
    default:
      return null
  }
}

const ProductDetails = ({ location }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [processing, setProcessing] = useState(true)
  const [product, setProduct] = useState(null)
  const [name, setName] = useState(null)
  const [leadCount, setLeadCount] = useState(null)
  const [padSize, setPadSize] = useState(null)
  const [leadframeMaterial, setLeadframeMaterial] = useState(null)
  const [dieAttach, setDieAttach] = useState(null)
  const [wirebond, setWirebond] = useState(null)
  const [bodySize, setBodySize] = useState(null)
  const [moldCoumpound, setMoldCoumpound] = useState(null)
  const [marking, setMarking] = useState(null)
  const [leadFinish, setLeadFinish] = useState(null)
  const [packaging, setPackaging] = useState(null)
  const [capability, setCapability] = useState(null)
  const [majorAssemblyProcess, setMajorAssemblyProcess] = useState(null)
  const [hermeticTesting, setHermeticTesting] = useState(null)
  const [testParameters, setTestParameters] = useState(null)
  const [frequencyRange, setFrequencyRange] = useState(null)
  const [subcategory, setSubcategory] = useState(null)

  const isBrowser = typeof window !== 'undefined'

  useEffect(() => {
    setProcessing(true)

    axios
      .get(
        `${
          isBrowser && window?.location?.origin === 'https://fastech.digiteer.dev'
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/${location.pathname}`,
      )
      .then(res => {
        if (res.data) {
          setProduct(res.data)
        } else {
          navigate('/404')
        }
      })
      .finally(() => setProcessing(false))
  }, [location.pathname])

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser && window?.location?.origin === 'https://fastech.digiteer.dev'
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/subcategories`,
      )
      .then(res => {
        setSubcategory(res.data)
      })
  }, [])

  useEffect(() => {
    setName(product?.product_specifications.filter(i => i.name !== ''))
    setLeadCount(product?.product_specifications.filter(i => i.lead_count !== ''))
    setPadSize(product?.product_specifications.filter(i => i.pad_size !== ''))
    setLeadframeMaterial(product?.product_specifications.filter(i => i.leadframe_material !== ''))
    setDieAttach(product?.product_specifications.filter(i => i.die_attach !== ''))
    setWirebond(product?.product_specifications.filter(i => i.wirebond !== ''))
    setBodySize(product?.product_specifications.filter(i => i.body_size !== ''))
    setMoldCoumpound(product?.product_specifications.filter(i => i.mold_compound !== ''))
    setMarking(product?.product_specifications.filter(i => i.marking !== ''))
    setLeadFinish(product?.product_specifications.filter(i => i.lead_finish !== ''))
    setPackaging(product?.product_specifications.filter(i => i.packaging !== ''))
    setCapability(product?.product_specifications.filter(i => i.capability !== ''))
    setMajorAssemblyProcess(
      product?.product_specifications.filter(i => i.major_assembly_process !== ''),
    )
    setHermeticTesting(product?.product_specifications.filter(i => i.hermetic_testing !== ''))
    setTestParameters(product?.product_specifications.filter(i => i.test_parameters !== ''))
    setFrequencyRange(product?.product_specifications.filter(i => i.frequency_range !== ''))
  }, [product])

  if (processing) {
    return null
  }

  return (
    <Site>
      <Seo title={product?.name} />
      <Section py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container pb={20}>
            <Row mb={15}>
              <Col col={{ md: 6 }} mb={{ _: 6, md: 0 }}>
                <Card bordered cardhidden="true">
                  <ScaledImage scaleHeight={282} scaleWidth={534}>
                    {product?.photo_url && <img src={product?.photo_url} alt={product?.name} />}
                  </ScaledImage>
                </Card>
              </Col>
              <Col col={{ md: 6 }}>
                <Subtitle size="caption" mb={2}>
                  {product?.product_category_id === 1
                    ? 'Power Semiconductors'
                    : 'RF and Microwave Modules'}
                </Subtitle>
                {product?.product_category_id === 2 && subcategory && (
                  <Title mb={1}>
                    {subcategory.find(i => i.id === product?.product_subcategory_id).name}
                  </Title>
                )}
                {product?.product_category}
                <Heading as="h1" size="h2" color="#000">
                  {product?.name}
                </Heading>
                <Element pt={10}>
                  <Button
                    variant="brand"
                    as={Link}
                    to="/contact-us"
                    state={{
                      productId: product?.id,
                      inquiryType: 'Customers',
                    }}
                  >
                    Inquire Now
                  </Button>
                </Element>
              </Col>
            </Row>
            {name?.length > 0 ? (
              <>
                <FieldGroup>
                  <FieldContainer>
                    <Field as="select" onChange={e => setActiveTab(e.target.value)}>
                      {name.map((ps, idx) => (
                        <option value={idx}>{ps.name}</option>
                      ))}
                    </Field>
                  </FieldContainer>
                </FieldGroup>

                <Tabs id="controlled-tab-example" activeKey={activeTab} className="mb-3">
                  {name.map((ps, idx) => (
                    <Tab value={ps.name} key={idx} eventKey={idx}>
                      <Table>
                        <tbody>
                          {product?.product_category_id === 1 && (
                            <>
                              {ps.lead_count && (
                                <tr>
                                  <td>Lead Count</td>
                                  <td>{ps.lead_count}</td>
                                </tr>
                              )}
                              {ps.pad_size && (
                                <tr>
                                  <td>Pad Size</td>
                                  <td dangerouslySetInnerHTML={{ __html: ps.pad_size }}></td>
                                </tr>
                              )}
                              {ps.leadframe_material && (
                                <tr>
                                  <td>Leadframe Material</td>
                                  <td>{ps.leadframe_material}</td>
                                </tr>
                              )}
                              {ps.die_attach && (
                                <tr>
                                  <td>Die Attach</td>
                                  <td>{ps.die_attach}</td>
                                </tr>
                              )}
                              {ps.wirebond && (
                                <tr>
                                  <td>Wirebond</td>
                                  <td>{ps.wirebond}</td>
                                </tr>
                              )}
                              {ps.body_size && (
                                <tr>
                                  <td>Body Size</td>
                                  <td dangerouslySetInnerHTML={{ __html: ps.body_size }}></td>
                                </tr>
                              )}
                              {ps.mold_compound && (
                                <tr>
                                  <td>Mold Compound</td>
                                  <td>{ps.mold_compound}</td>
                                </tr>
                              )}
                              {ps.marking && (
                                <tr>
                                  <td>Marking</td>
                                  <td>{ps.marking}</td>
                                </tr>
                              )}
                              {ps.lead_finish && (
                                <tr>
                                  <td>Lead Finish</td>
                                  <td>{ps.lead_finish}</td>
                                </tr>
                              )}
                              {ps.packaging && (
                                <tr>
                                  <td>Packaging</td>
                                  <td>{ps.packaging}</td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </Table>
                    </Tab>
                  ))}
                </Tabs>
              </>
            ) : (
              <Table>
                <tbody>
                  {product?.product_category_id === 1 && (
                    <>
                      {leadCount?.length > 0 && (
                        <tr>
                          <td>Lead Count</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.lead_count}</td>
                          ))}
                        </tr>
                      )}
                      {padSize?.length > 0 && (
                        <tr>
                          <td>Pad Size</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx} dangerouslySetInnerHTML={{ __html: lc.pad_size }}></td>
                          ))}
                        </tr>
                      )}
                      {leadframeMaterial?.length > 0 && (
                        <tr>
                          <td>Leadframe Material</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.leadframe_material}</td>
                          ))}
                        </tr>
                      )}
                      {dieAttach?.length > 0 && (
                        <tr>
                          <td>Die Attach</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.die_attach}</td>
                          ))}
                        </tr>
                      )}
                      {wirebond?.length > 0 && (
                        <tr>
                          <td>Wirebond</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.wirebond}</td>
                          ))}
                        </tr>
                      )}
                      {bodySize?.length > 0 && (
                        <tr>
                          <td>Body Size</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx} dangerouslySetInnerHTML={{ __html: lc.body_size }}></td>
                          ))}
                        </tr>
                      )}
                      {moldCoumpound?.length > 0 && (
                        <tr>
                          <td>Mold Compound</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.mold_compound}</td>
                          ))}
                        </tr>
                      )}
                      {marking?.length > 0 && (
                        <tr>
                          <td>Marking</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.marking}</td>
                          ))}
                        </tr>
                      )}
                      {leadFinish?.length > 0 && (
                        <tr>
                          <td>Lead Finish</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.lead_finish}</td>
                          ))}
                        </tr>
                      )}
                      {packaging?.length > 0 && (
                        <tr>
                          <td>Packaging</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.packaging}</td>
                          ))}
                        </tr>
                      )}
                    </>
                  )}
                  {product?.product_category_id === 2 && (
                    <>
                      {capability?.length > 0 && (
                        <tr>
                          <td>Capability</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.capability}</td>
                          ))}
                        </tr>
                      )}
                      {majorAssemblyProcess?.length > 0 && (
                        <tr>
                          <td>Major Assembly Process</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.major_assembly_process}</td>
                          ))}
                        </tr>
                      )}
                      {hermeticTesting?.length > 0 && (
                        <tr>
                          <td>Hermetic Testing</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.hermetic_testing}</td>
                          ))}
                        </tr>
                      )}
                      {testParameters?.length > 0 && (
                        <tr>
                          <td>Test Parameters</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.test_parameters}</td>
                          ))}
                        </tr>
                      )}
                      {frequencyRange?.length > 0 && (
                        <tr>
                          {hermeticTesting?.length > 0 && (
                            <tr>
                              <td>Hermetic Testing</td>
                              {product?.product_specifications.map((lc, idx) => (
                                <td key={idx}>{lc.hermetic_testing}</td>
                              ))}
                            </tr>
                          )}
                          <td>Frequency Range</td>
                          {product?.product_specifications.map((lc, idx) => (
                            <td key={idx}>{lc.frequency_range}</td>
                          ))}
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            )}
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  )
}

const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  tr {
    td:first-child {
      width: 25%;
      font-weight: bold;
    }

    td {
      border-top: 1px solid #9e9e9e;
      border-left: 1px solid #9e9e9e;
      padding: 10px 8px;

      &:last-child {
        border-right: 1px solid #9e9e9e;
      }
    }

    &:last-child td {
      border-bottom: 1px solid #9e9e9e;
    }
  }
`

export default ProductDetails
