import { color, space, typography } from "styled-system";
import styled from "styled-components";
import { Heading } from "../UI/Typography";
import theme from "../../utils/theme";
import { Paragraph } from "../UI/Typography";
import { handleResponsiveSize } from "../UI/Typography";

export const Subtitle = styled(Heading)`
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: ${theme.colors.secondary};
  ${color};
  ${typography};
  ${space};
`;

export const Title = styled(Heading)`
  font-weight: ${theme.fontWeights.bold};
  line-height: 1.2;
  letter-spacing: -0.02em;
  ${(p) =>
    p.size ? handleResponsiveSize(p.size) : handleResponsiveSize("h2")};
  ${color};
  ${typography};
  ${space};
`;

export const SmallerTitle = styled(Heading)`
  font-weight: ${theme.fontWeights.bold};
  line-height: 1.2;
  letter-spacing: -0.02em;
  ${(p) => (p.fontSize ? p.fontSize : handleResponsiveSize("h4"))};
  ${color};
  ${typography};
  ${space};
`;

export const Description = styled(Paragraph)``;
