import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'
import { space, typography } from 'styled-system'
import theme from '../../utils/theme'

const Button = styled.button`
  display: ${p => (p.block ? 'flex' : 'inline-flex')};
  width: ${p => (p.block ? '100%' : 'auto')};
  min-height: 44px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 4px 16px;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  transition: ${theme.transition};
  font-weight: ${theme.fontWeights.semibold};

  ${media.lg`
    padding: 4px 40px;
    min-height: ${p => (p.size === 'small' ? '40px' : p.size === 'xsmall' ? '32px' : '48px')};
    font-size: ${p => (p.size === 'small' ? '14px' : p.size === 'xsmall' ? '14px' : '1rem')};
  `}

  &:focus {
    outline: none;
  }

  div + span,
  span + div {
    margin-left: 8px;
  }
  svg {
    font-size: 20px;
  }

  ${p =>
    p.variant === 'brand' &&
    css`
      border: 1px solid ${theme.colors.brand};
      background: ${theme.colors.brand};
      color: ${theme.colors.white};

      &:hover,
      &:focus {
        background: ${theme.colors.brandHover};
        color: ${theme.colors.white};
      }
    `}

  ${p =>
    p.variant === 'brand-outline' &&
    css`
      border: 1px solid var(--brand);
      background: none;
      color: var(--brand);

      &:hover,
      &:focus {
        background: ${theme.colors.brandHover};
        color: ${theme.colors.white};
      }
    `}

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${p =>
    p.variant === 'white' &&
    css`
      border: 1px solid ${theme.colors.white};
      background: ${theme.colors.white};
      color: ${theme.colors.brand};

      &:hover,
      &:focus {
        background: #aaa;
        border-color: #aaa;
        color: ${theme.colors.brand};
      }
    `}
  ${space};
  ${typography};
`

export default Button
